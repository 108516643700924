.collection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1320px;
  padding-inline: 20px;
  gap: 40px;
  margin: 3rem 0rem;
}
.collection h1 {
  align-self: flex-start;
  font-size: 30px;
  color: #111;
  font-weight: 500;
}
.collection h2 {
  align-self: flex-start;
  font-size: 62px;
  color: #111;
  font-weight: 300;
  font-family: "Inter";
  letter-spacing: -.05em;
  line-height: 68px;
}


.trendingCont {
  margin-top: 6rem;
}

@media screen and (max-width: 992px) {
  .cardsGrid {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 686px) {
  .cardsGrid {
    grid-template-columns: 1fr;
  }
  .collection h1 {
    align-self: center;
    text-align: center;
  }
}
