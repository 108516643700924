.activityTab {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 10px;
  background-color: white;
  border: 2px solid rgba(22, 22, 26, 0.1);
  border-radius: 14px;
  padding: 10px;
}
.activityTab img {
  width: 40px;
  height: 40px;
  border-radius: 14px;
  object-fit: contain;
}
.tabDesc {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  line-height: 1;
  flex-direction: column;
}
.tabDesc h2 {
  font-size: 14px;
}
.tabDesc h3 {
  color: var(--grey);
  font-size: 10px;
  word-break: break-all;
}
.tabDesc p {
  color: var(--grey);
  font-size: 10px;
  align-content: right;
}
@media screen and (max-width: 610px) {
  .activityTab {
    flex-direction: column;
    align-items: center;
  }
  .tabDesc {
    align-items: center;
    gap: 5px;
    text-align: center;
  }
  .activityTab img {
    width: 120px;
    height: 120px;
  }
}
