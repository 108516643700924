.priceBox {
  isolation: isolate;
  border-radius: 10px;
  width: 100%;
  height: 260px;
  padding: 2px;
  position: relative;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.mainBox {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 30px;
  height: 100%;
  border-radius: 20px;
  padding: 20px;
}

.priceBox:after {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: -1;
  bottom: 0px;
  height: 100%;
  border-radius: 20px;
  background-image: linear-gradient(82deg, #36beff, #733ff1);
  opacity: 1;
  transition: opacity 0.2s, transform 0.2s;
}

.priceBox h2 {
  font-size: 50px;
  color: var(--purple);
  font-weight: 300;
}

.priceBox p {
  font-size: 20px;
}
.mainBox a {
  padding: 12px 30px;
  gap: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
