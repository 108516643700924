.profile {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 100px;
  padding-inline: 40px;
  min-height: 100vh;
}
.topProfile {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 16px;
}
.topProfile img {
  border: 4px solid white;
  border-radius: 50%;
  width: 132px;
  height: 132px;
  position: relative;
  top: 34px;
  left: 20px;
}

.profileDesc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 50px;
}
.desc {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}
.desc h1 {
  font-size: 32px;
  color: #111;
  font-weight: 500;
}
.desc .joinDate {
  font-size: 16px;
  color: var(--grey);
}
.desc p {
  color: rgba(22, 22, 26, 0.6);
  font-size: 15px;
  font-weight: 500;
}
.btnDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.btnDiv button,
.btnDiv a {
  padding: 0px 20px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btnDiv button:first-of-type {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.followBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  max-width: 328px;
  width: 100%;
  border: 1px solid rgba(22, 22, 26, 0.08);
  border-radius: 24px;
  padding: 20px;
}
.followRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.followRow .address {
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 1rem;
  text-align: right;
}
.followRow h3,
.followRow p {
  color: rgba(22, 22, 26, 0.6);
}
.followRow h3 {
  font-weight: 500;
  font-size: 16px;
}
.followRow p {
  font-weight: 600;
}
.followBox .followRow:last-of-type {
  padding-top: 15px;
  border-top: 1px solid rgba(22, 22, 26, 0.08);
}
.nftCategory {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  width: 100%;
  border-bottom: 1px solid rgba(22, 22, 26, 0.08);
  padding-bottom: 15px;
  margin-top: 40px;
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
}
.category {
  font-size: 16px;
  color: rgba(22, 22, 26, 0.6);
  cursor: pointer;
  text-decoration: none;
  position: relative;
  min-width: max-content;
}
.category span {
  color: rgba(22, 22, 26, 0.6);
  margin-left: 6px;
  background: rgba(22, 22, 26, 0.1);
  border-radius: 4px;
  padding: 2px 4px;
  height: 16px;
  font-size: 10px;
}
.category:hover {
  color: #111;
}
.activeCat::before {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -16px;
  height: 2px;
  border-radius: 100vh;
  background-color: #111;
}
.activeCat {
  color: #111;
}
.activeCat span {
  background-color: #111;
  color: white;
}
.filterBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}
.leftFilter {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.dropDown {
  min-width: 120px;
  width: auto;
  position: relative;
}
.dropDown button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-color: rgba(22, 22, 26, 0.1);
  color: rgb(22, 22, 26);
  background: transparent;
  padding: 0px 15px;
  height: 40px;
  border-radius: 14px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 900;
  position: relative;
}
.dropDown button svg {
  margin-left: 20px;
  font-size: 10px;
}
.absFilter {
  position: absolute;
  height: 14px;
  padding: 0px 5px;
  line-height: 14px;
  color: rgba(22, 22, 26, 0.6);
  border-radius: 4px;
  top: -7px;
  background: white;
  font-size: 11px;
  left: 10px;
}
.cardsGrid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-rows: 1fr;
  gap: 20px;
  margin-top: 30px;
  place-items: center;
  min-height: 500px;
}
.leftFilter .dropDown:last-of-type {
  display: none;
}
.mainDrop {
  position: absolute;
  top: 120%;
  left: 0;
  pointer-events: none;
  width: 300px;
  height: 290px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  background: rgb(255, 255, 255);
  box-shadow: rgb(27 32 50 / 10%) 0px 6px 16px;
  border: 1px solid rgba(22, 22, 26, 0.08);
  z-index: 6;
  border-radius: 20px;
  gap: 10px;
  transform: translateY(40px);
  opacity: 0;
  transition: all 0.4s ease-in-out;
}

.mainDrop ul {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  list-style: none;
  max-height: 100%;
  overflow-y: auto;
}
.mainDrop ul li {
  padding: 10px 0px;
  cursor: pointer;
}
.mainDrop ul::-webkit-scrollbar-track,
.subDrop::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.mainDrop ul::-webkit-scrollbar,
.subDrop::-webkit-scrollbar {
  width: 4px;
  background-color: rgba(22, 22, 26, 0.08);
}

.mainDrop ul::-webkit-scrollbar-thumb,
.subDrop::-webkit-scrollbar-thumb {
  border-radius: 100vh;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.btnDivDrop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
  padding-top: 20px;
  border-top: 1px solid rgba(22, 22, 26, 0.08);
}
.btnDivDrop button {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
}
.btnDivDrop .disabled {
  background-color: rgba(22, 22, 26, 0.08);
  color: rgba(22, 22, 26, 0.6);
  cursor: not-allowed;
  border-color: transparent;
}
.btnDivDrop .black {
  border-color: rgb(22, 22, 26);
  color: rgb(255, 255, 255);
  background: rgb(22, 22, 26);
}
.inputRowDrop {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
}
.inputRowDrop input {
  flex: 1;
  width: 100%;
  background: rgba(22, 22, 26, 0.04);
  border: 1px solid transparent;
  border-radius: 16px;
  min-height: 46px;
  padding-left: 10px;
}
.inputRowDrop input:focus {
  background-color: white;
  border-color: black;
  outline: none;
}
.smallHeight {
  height: auto;
  gap: 20px;
}
.subDropDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}
.subDropDiv button {
  font-size: 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-color: rgba(22, 22, 26, 0.1);
  color: rgb(22, 22, 26);
  background: transparent;
  padding: 10px 14px;
  height: 50px;
}
.subDrop {
  position: absolute;
  top: 120%;
  left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 10px 0px;
  background: rgb(255, 255, 255);
  box-shadow: rgb(27 32 50 / 10%) 0px 6px 16px;
  border: 1px solid rgba(22, 22, 26, 0.08);
  border-radius: 20px;
  max-height: 200px;
  height: 200px;
  overflow-y: auto;
  z-index: 4;
  transform: translateY(40px);
  opacity: 0;
  transition: all 0.4s ease-in-out;
}
.subDrop p {
  padding: 20px 20px;
  cursor: pointer;
  width: 100%;
}
.subDrop p:hover {
  background-color: #efefef;
}
.sortDrop {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  padding: 20px 0px;
  background: rgb(255, 255, 255);
  box-shadow: rgb(27 32 50 / 10%) 0px 6px 16px;
  border: 1px solid rgba(22, 22, 26, 0.08);
  border-radius: 20px;
  position: absolute;
  width: 220px;
  right: 0;
  top: 120%;
  height: auto;
  z-index: 5;
  transform: translateY(40px);
  opacity: 0;
  transition: all 0.4s ease-in-out;
}
.sortDrop h4 {
  color: rgba(22, 22, 26, 0.6);
  font-size: 13px;
  font-weight: 500;
  padding: 0px 20px;
}
.sortDrop h2 {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  padding: 10px 20px;
  width: 100%;
}
.sortDrop h2:hover {
  background-color: #efefef;
}
.activeDrop {
  transform: translateY(0px);
  opacity: 1;
  pointer-events: all;
}
.activityGrid {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
  margin-bottom: 50px;
}

.offers {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 100px;
  margin-top: 30px;
}
.offerBtn {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.offerBtn p {
  font-size: 18px;
}
.offerBtn p svg {
  margin-right: 10px;
}
.offerTable {
  width: 100%;
  overflow-x: auto;
}
.offerTable table {
  border-collapse: collapse;
  text-align: left;
  width: 100%;
}
.offerTable th {
  background-color: #eee;
  border-top: 1px solid #ccc;
}
.offerTable th,
.offerTable td {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #ccc;
}
.offerTable tr th {
  background-color: white;
}
.offerTable tr td {
  background-color: var(--light-purple);
}
.offerTable tr:last-of-type td {
  border: none;
}
.imgOffer {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
}
@media screen and (max-width: 1500px) {
  .cardsGrid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (max-width: 1084px) {
  .cardsGrid {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 900px) {
  .profileDesc {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
  .followBox {
    max-width: 450px;
    width: 100%;
  }
  .leftFilter {
    justify-content: center;
    gap: 30px;
    height: 100%;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .dropDown,
  .dropDown button {
    width: 100%;
  }
  .filterBar > .dropDown {
    display: none;
  }
  .leftFilter .dropDown:last-of-type {
    display: inline-block;
  }
  .mainDrop,
  .sortDrop {
    width: 100%;
    z-index: -1;
  }
  .activeDrop {
    z-index: 6;
  }
}
@media screen and (max-width: 740px) {
  .cardsGrid {
    grid-template-columns: 1fr;
  }
  .offers {
    align-items: flex-start;
  }
}
@media screen and (max-width: 450px) {
  .profile {
    padding-inline: 20px;
  }
}
