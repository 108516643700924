.nftDetail {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1400px;
  margin-top: 100px;
  gap: 1rem;
  padding-inline: 20px;
  margin-bottom: 50px;
}
.suggestedListings {
  max-width: 1400px;
  width: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 40px;
  padding-inline: 20px;
}
.suggestedListings li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.suggestedListings h1 {
  font-size: 30px;
  font-weight: 500;
}
.nftName {
  font-size: 30px;
  color: rgba(17, 17, 17, 0.674);
  font-weight: 500;
}
.topDetailRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.shareRow {
  display: flex;
  justify-content: center;
  align-items: center;
}
.shareRow p:first-of-type {
  margin-right: 20px;
}
.shareRow p {
  text-decoration: underline;
  font-size: 14px;
}
.shareRow svg {
  color: black;
  background-color: white;
  width: 30px;
  height: 30px;
  box-sizing: border-box !important;
  padding: 9px;
  cursor: pointer;
  box-shadow: 0px 5px 10px rgb(29 71 123 / 4%), 0px 1px 5px rgb(29 71 123 / 12%);
  margin-right: 5px;
  border-radius: 50%;
}
.shareRow svg:hover {
  opacity: 0.6;
}
.imgDiv {
  display: grid;
  grid-template-columns: 0.7fr 0.3fr;
  width: 100%;
  gap: 20px;
}
.assetMap {
  width: 100%;
  height: 350px;
}
.imgDiv > img {
  max-width: 100%;
  width: 100%;
  object-fit: cover;
  height: 460px;
  border-radius: 20px;
  cursor: pointer;
}
.flexCol {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.flexCol button,
.flexCol a {
  width: 100%;
  padding: 15px 20px;
}
.imgCol {
  width: 100%;
  gap: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.imgCol img {
  height: calc(450px / 2);
  width: 100%;
  border-radius: 20px;
  cursor: pointer;
  object-fit: cover;
}
.bottomNft {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  position: relative;
  gap: 3rem;
  margin-top: 3rem;
}
.descNft {
  flex: 0.7;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}
.details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 100%;
}
.col {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  background-color: rgba(22, 22, 26, 0.04);
  padding: 16px;
  border-radius: 14px;
  width: 200px;
}
.col h3 {
  color: var(--grey);
  font-size: 16px;
  font-weight: 300;
}
.descNft h1 {
  font-size: 30px;
  font-weight: 500;
}
.col p {
  color: #111;
  font-size: 18px;
  font-weight: 500;
  margin-top: 6px;
}
.mainDesc {
  font-size: 18px;
  color: #111;
}
.descNft h6 {
  color: var(--grey);
  font-size: 14px;
}
.featureDiv {
  display: grid;
  grid-template-columns: 200px 200px 200px;
  grid-auto-rows: 1fr;
  width: 100%;
  gap: 20px;
}
.hr {
  width: 100%;
  background-color: var(--grey);
  height: 2px;
  opacity: 0.2;
}
.rightHeader button {
  padding: 10px 50px;
}
.btnDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}
.btnDiv button {
  padding: 15px 30px;
}
.stickyBox {
  flex: 0.3;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: sticky;
  top: 110px;
}
.boxWrapper {
  width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 10px 22px rgb(29 71 123 / 8%), 0 2px 12px rgb(29 71 123 / 8%);
  border-radius: 16px;
  border-color: #eef4fa;
  padding: 1.5rem;
  background-color: white;
  gap: 20px;
}
.boxDesc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.boxCol {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.mobBox {
  display: none;
}
.boxWrapper button,
.mobBox button {
  width: 100%;
  padding: 0;
  height: 50px;
  font-size: 16px;
}
.mobileSlider {
  display: none;
}
.bottomNft ul {
  display: none;
}
.moreDetails {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  margin: 1rem 0rem;
  gap: 25px;
}
.moreDetails table {
  width: 100%;
  max-width: 800px;
  text-align: center;
  border-radius: 10px;
  overflow: hidden;
  border-collapse: collapse;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
}
.moreDetails table th {
  background-color: white;
  font-size: 16px;
  padding: 10px 0px;
  color: black;
}
.moreDetails table td {
  font-size: 16px;
  padding: 10px 0px;
  color: black;
}

.moreDetails tr td {
  background-color: var(--light-purple);
}
.moreDetails h2,
.agent h2 {
  font-size: 30px;
  font-weight: 500;
}
.moreDetailsRow {
  text-decoration: none;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 500px;
}
.moreDetailsRow p {
  font-size: 18px;
  color: var(--grey);
}
.moreDetailsRow h3 {
  font-size: 18px;
  font-weight: bold;
}
.owner {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
  width: 100%;
}
.owner img {
  max-width: 50px;
  height: 50px;
  object-fit: contain;
  border-radius: 50%;
}
.ownerDetails {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 4px;
  overflow: hidden;
}
.ownerDetails h2 {
  font-size: 16px;
  font-weight: 400;
  color: var(--grey);
}
.ownerDetails p {
  font-size: 18px;
  font-weight: 600;

  text-overflow: ellipsis;
  white-space: nowrap;
  width: 200px;
}
.listOwner {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin: 10px 0px;
}
.listOwner a {
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  text-decoration: none;
  color: var(--grey);
}
.listOwner a svg {
  width: 25px;
  height: 25px;
}
.callRow {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.callRow a:first-of-type {
  color: #1da1f2;
  font-size: 20px;
  cursor: pointer;
}
.reviews {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.reviews svg {
  font-size: 14px;
  color: var(--purple);
}
.reviews p {
  font-size: 14px;
  color: var(--grey);
}
.agentBox p {
  max-width: 200px;
  text-align: center;
}
.callRow a:last-of-type {
  color: black;
  font-size: 20px;
  cursor: pointer;
}
.listOwner a img {
  width: 25px;
  border-radius: 50%;
  height: 25px;
  filter: contrast(0);
}
.listOwner a .up {
  width: 15px;
  height: 15px;
  margin-left: -10px;
  transform: rotate(45deg);
}
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 90%;
  max-width: 500px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 40px 20px;
  border-radius: 15px;
  background-color: white;
  z-index: 22;
}
.modal svg {
  position: absolute;
  top: 3%;
  right: 5%;
  font-size: 20px;
  cursor: pointer;
}
.modal h1 {
  margin-bottom: 20px;
  font-size: 30px;
}
.overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 30px;
}
.modalDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}
.modalDiv input,
.modalDiv textarea {
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--grey);
  padding-left: 10px;
  font-size: 16px;
  height: 40px;
}
.modalDiv textarea {
  height: 160px;
  resize: none;
  border: 1px solid var(--grey);
  padding: 10px;
}
.modalDiv input:focus {
  outline: none;
}
.modalDiv label {
  font-size: 16px;
  color: var(--grey);
}
.modal form button {
  width: 100%;
  height: 50px;
  font-size: 20px;
}
.agent {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin-top: 40px;
}
.agentRow {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  flex-wrap: wrap;
}
.agentBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  border-radius: 5px;
  background-color: white;
  box-shadow: rgb(0 0 0 / 15%) 0px 6px 25px;
  padding: 25px;
}
.agentBox img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.agentBox h3 {
  font-size: 16px;
  font-weight: 500;
}
.agentBox h4 {
  font-size: 12px;
  font-weight: 400;
}
@media screen and (max-width: 1284px) {
  .suggestedListings li {
    justify-content: center;
  }
}
@media screen and (max-width: 1084px) {
  .imgDiv {
    display: none;
  }
  .mobileSlider {
    display: block;
  }
  .mobileSlider img {
    width: 100%;
    max-height: 600px;
    object-fit: cover;
  }
  .stickyBox {
    display: none;
  }
  .mobBox {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    padding: 20px;
    border-top: 1px solid #eee;
    box-shadow: 0 10px 22px rgb(29 71 123 / 8%), 0 2px 12px rgb(29 71 123 / 8%);
    z-index: 4;
  }
  .mobBox button {
    width: 200px;
  }
  .descNft {
    flex: none;
    width: 100%;
  }
  .bottomNft {
    margin-top: 1rem;
  }
}
@media screen and (max-width: 700px) {
  .overflowTable {
    overflow-x: scroll;
  }
  .moreDetails table {
    min-width: 600px;
  }
}
@media screen and (max-width: 690px) {
  .details {
    display: none;
  }
  .bottomNft ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 10px;
    flex-wrap: wrap;
    list-style-position: inside;
  }
  .bottomNft li {
    font-size: 16px;
    font-weight: bold;
    color: var(--grey);
  }
  .btnDiv button {
    padding: 0;
    width: 100%;
    height: 50px;
    font-size: 16px;
  }
  .btnDiv {
    flex-direction: column;
    width: 100%;
  }
  .featureDiv {
    grid-template-columns: 1fr 1fr;
  }
  .mobBox button {
    width: 120px;
  }
}
@media screen and (max-width: 480px) {
  .topDetailRow {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
  .featureDiv {
    grid-template-columns: 1fr;
    place-items: center;
  }
  .featureDiv .col {
    width: 100%;
    max-width: 100%;
  }
}
