.createHeader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 1320px;
  margin-top: 150px;
}
.createHeader h1 {
  color: black;
  padding-left: 20px;
  font-size: 50px;
}

.createBottom {
  width: 100%;
  max-width: 1320px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 60px;
  margin-top: 3rem;
  padding-inline: 20px;
}

.leftBottom {
  flex: 0.6;
}
.rightBottom {
  flex: 0.4;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}
.rightBottom h1 {
  font-size: 17px;
}
.leftBottom form {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 30px;
}
.inputCol,
.inputCol2 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}
.inputCol h2,
.inputCol2 h2 {
  font-size: 17px;
}

.inputCol2 > * {
  width: 100%;
}

.inputCol input,
.inputCol textarea,
.toggleInput,
.inputRow input {
  padding-left: 8px;
  border: 1px solid #ccc;
  background: none;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  height: 42px;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  color: #333;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  width: 100%;
}
.inputRow { 
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 15px;
}
.inputCol textarea {
  height: 100px !important;
  resize: none;
  padding-top: 8px;
}
.file {
  display: none;
}
.uploadDiv {
  width: 100%;
  height: 180px;
  border-radius: 10px;
  border: 3px dashed #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.uploadedImage {
  width: 100%;
  height: 180px;
  object-fit: cover;
}
.selectedImgsList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.selectedImgsList img {
  width: 100px;
  border: 1px solid var(--purple);
  border-radius: 900px;
}
.uploadDiv h4 {
  font-size: 15px;
}
.uploadDiv h6,
.leftBottom form button {
  padding: 16px 100px;
  font-size: 18px;
}
.uploadBtn button {
  padding: 15px 40px !important;
  font-size: 16px !important;
}
.row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.methodBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 140px;
  height: 135px;
  color: rgba(0, 0, 0, 0.6);
  background: none;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  gap: 10px;
  cursor: pointer;
}
.methodBox h3 {
  font-size: 14px;
  font-weight: 500;
}
.methodBox svg {
  font-size: 30px;
}
.activeBox {
  border-color: var(--purple);
}
.activeBox svg {
  color: var(--purple);
}
.toggleRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.leftToggle {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.leftToggle h2 {
  font-size: 18px;
}
.leftToggle h2 svg {
  color: var(--purple);
}
.leftToggle p {
  color: rgb(114, 114, 114);
  font-size: 14px;
}
.inRow {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 100%;
}
.inRow .inputCol input {
  padding-inline: 8px;
}
.dropDown {
  min-width: 190px;
  width: auto;
  position: relative;
  background-color: white;
  box-shadow: rgb(27 32 50 / 10%) 0px 6px 16px;
  border-radius: 10px;
}
.dropDown h6 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-color: rgba(22, 22, 26, 0.1);
  color: black;
  padding: 15px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 900;
}
.dropDown h6 svg {
  margin-left: 20px;
  font-size: 10px;
}
.mainDrop {
  position: absolute;
  top: 120%;
  left: 0;
  width: 300px;
  height: 290px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  background: rgb(255, 255, 255);
  box-shadow: rgb(27 32 50 / 10%) 0px 6px 16px;
  border: 1px solid rgba(22, 22, 26, 0.08);
  z-index: 6;
  border-radius: 20px;
  gap: 10px;
  transform: translateY(40px);
  opacity: 0;
  transition: all 0.4s ease-in-out;
  z-index: -1;
}

.mainDrop ul {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  list-style: none;
  max-height: 100%;
  overflow-y: auto;
}
.mainDrop ul li {
  padding: 10px 0px;
  cursor: pointer;
  width: 100%;
}
.mainDrop ul::-webkit-scrollbar-track,
.subDrop::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.mainDrop ul::-webkit-scrollbar,
.subDrop::-webkit-scrollbar {
  width: 4px;
  background-color: rgba(22, 22, 26, 0.08);
}

.mainDrop ul::-webkit-scrollbar-thumb,
.subDrop::-webkit-scrollbar-thumb {
  border-radius: 100vh;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}
.activeDrop {
  transform: translateY(0px);
  opacity: 1;
  z-index: 3;
}
@media screen and (max-width: 935px) {
  .createBottom {
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
  }
  .leftBottom,
  .rightBottom {
    flex: none;
    width: 100%;
  }
  .row {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 508px) {
  .inputRow {
    justify-content: center;
    flex-direction: column;
  }
}
.relative {
  position: relative;
}
.stateDrop {
  width: 100%;
  position: absolute;
  top: 100%;
  max-height: 500px;
  height: 500px;
  overflow-y: auto;
  left: 0;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  box-shadow: 0px 9px 10px 0px rgba(0, 0, 0, 0.1);
}

.searchedItem {
  width: 100%;
  padding: 20px;
  color: #111;
  font-size: 18px;
  text-decoration: none;
}
.searchedItem:hover {
  background-color: #efefef;
}
