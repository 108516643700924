.listNftWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 78px);
  width: 100%;
  padding-inline: 20px;
  margin: 120px 0px;
}
.listNft {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 1000px;
  gap: 80px;
}
.listNft form {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 40px;
  align-items: flex-start;
}
.listNft form h2 {
  margin-bottom: 10px;
}
.dropDown {
  min-width: 120px;
  width: auto;
  height: 100%;
  position: relative;
}
.dropDown button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-color: rgba(22, 22, 26, 0.1);
  color: rgb(22, 22, 26);
  background: transparent;
  padding: 0px 10px;
  height: 100%;
  border-radius: 14px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 900;
  position: relative;
  width: 100%;
}
.dropDown button svg {
  font-size: 10px;
}
.dropDown button p {
  display: flex;
  justify-content: center;
  gap: 3px;
  font-size: 16px;
  align-items: center;
}
.dropDown button p svg {
  font-size: 16px;
}
.mainDrop {
  position: absolute;
  top: 120%;
  left: 0;
  width: 300px;
  max-height: 190px;
  height: auto;
  display: none;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  background: rgb(255, 255, 255);
  box-shadow: rgb(27 32 50 / 10%) 0px 6px 16px;
  border: 1px solid rgba(22, 22, 26, 0.08);
  z-index: -1;
  border-radius: 20px;
  pointer-events: none;
  gap: 10px;
  transition: all 0.4s ease-in-out;
}

.mainDrop ul {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  list-style: none;
  max-height: 100%;
  overflow-y: auto;
}
.mainDrop ul li {
  padding: 10px 0px;
  width: 100%;
  cursor: pointer;
}
.mainDrop ul::-webkit-scrollbar-track,
.subDrop::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.mainDrop ul::-webkit-scrollbar,
.subDrop::-webkit-scrollbar {
  width: 4px;
  background-color: rgba(22, 22, 26, 0.08);
}

.mainDrop ul::-webkit-scrollbar-thumb,
.subDrop::-webkit-scrollbar-thumb {
  border-radius: 100vh;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}
.activeDrop {
  display: flex;
  z-index: 6;
  pointer-events: all;
}
.inputCol {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 5px;
}
.inputRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 5px;
  height: 48px;
}
.inputRow h5,
.inputRow h3 {
  font-weight: 600;
  font-size: 16px;
  color: rgb(4, 17, 29);
}
.inputRow p,
.inputCol > p {
  color: rgb(53, 56, 64);
  font-weight: 400;
  font-size: 14px;
}
.inputCol > .inputRow:first-of-type {
  height: auto;
  margin-bottom: 10px;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
}
.inputCol > p {
  align-self: flex-end;
}
.inputRow input {
  width: 100%;
  height: 100%;
  border-color: rgba(22, 22, 26, 0.1);
  color: rgb(22, 22, 26);
  border-radius: 14px;
  padding-inline: 10px;
  font-size: 16px;
}
.inputDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.inputDiv input {
  height: 24px;
  width: 24px;
}
.inputDiv label {
  font-weight: 400;
  font-size: 18px;
  color: rgb(4, 17, 29);
  font-size: 16px;
  line-height: 1.6;
}
.fees {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  border-top: 1px solid #c1c1c1;
  padding-top: 20px;
  margin-top: 20px;
}
.fees .inputRow {
  height: auto;
}
.listNft form > button {
  width: 100%;
  max-width: 300px;
  padding: 20px 0px;
  font-size: 17px;
}
.inputRow h3 {
  font-size: 18px;
}
.inputRow p {
  color: #555;
  font-size: 14px;
  font-weight: 500;
}
.fees .inputRow svg {
  color: #555;
  font-size: 20px;
}
.rightList {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  position: sticky;
  top: 105px;
}
.typeRow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.typeBox {
  border: 2px solid rgb(229, 232, 235);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 108px;
  gap: 8px;
  cursor: pointer;
  transition: all 0.2s ease 0s;
  color: rgb(4, 17, 29);
}
.typeRow .typeBox:first-of-type {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-right: 0px;
}
.typeRow .typeBox:last-of-type {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.typeBox svg {
  font-size: 20px;
}
.typeBox h2 {
  margin-bottom: 0px !important;
  font-size: 18px;
}
.activeType {
  background-color: rgb(243, 251, 254);
}
.typeBox:hover {
  box-shadow: rgb(0 0 0 / 10%) 0px 2px 10px;
}
@media screen and (max-width: 1002px) {
  .listNft {
    flex-direction: column;
  }
  .rightList {
    position: static;
    width: 100%;
    align-items: center;
  }
}
@media screen and (max-width: 546px) {
  .flex {
    flex-direction: column;
  }
  .typeBox h2 {
    font-size: 14px;
  }
}
