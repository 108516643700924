.pricing {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1320px;
  padding-inline: 20px;
  gap: 40px;
  margin: 3rem 0rem;
  margin-bottom: 100px;
}
.pricing h1 {
  font-size: 40px;
  color: #111;
  font-weight: 500;
}
.row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  width: 100%;
}
@media screen and (max-width: 1015px) {
  .row {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
  .row div:last-of-type {
    grid-column: 1/3;
  }
}
@media screen and (max-width: 600px) {
  .row {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
  .row div:last-of-type {
    grid-column: auto;
  }
}
