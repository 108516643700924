.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 1320px;
  margin-top: 150px;
  padding-bottom: 40px;
  padding-inline: 20px;
}
.subHead {
  color: var(--purple);
  font-size: 20px;
}
.head {
  font-size: 50px;
  font-weight: 800;
  text-align: center;
}
.boxRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 40px;
}
.box {
  border-radius: 1.5rem;
  background-color: #fff;
  box-shadow: 1px 1px 1.875rem 0 rgba(0, 106, 255, 0.42);
  width: 357px;
  padding: 2.9375rem 1.5rem 3.1875rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
  height: 400px;
  gap: 20px;
}
.box h1 {
  font-size: 50px;
  font-weight: 700;
}
.box p {
  font-size: 16px;
  text-align: center;
}
.box p:first-of-type {
  max-width: 70%;
}
.box h2 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-size: 3rem;
 
  font-weight: bold;
  color: var(--purple);
}
.box h2 sup {
  font-size: 23px;
}
.box button {
  width: 100%;
  height: 50px;
  font-size: 18px;
}
.box hr {
  width: 100%;
  height: 2px;
  background-color: var(--grey);
  margin: 10px 0px;
}
.box h3 {
  text-align: center;
  font-size: 20px;
}
.box ul {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
}
.boxTop {
  width: 70%;
  height: 30px;
  position: absolute;
  top: 0%;
  left: 50%;
  color: var(--purple);
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 16px;
  display: grid;
  place-items: center;
  font-weight: bold;
  border-radius: 100vh;
  transform: translate(-50%, -50%);
}
.orange {
  background-image: linear-gradient(52deg, #ffd27b, #ffab90);
}
.blue {
  background-image: linear-gradient(159deg, #72fcc2, #50d5ff);
}
@media screen and (max-width: 1153px) {
  .boxRow {
    justify-content: center;
    gap: 40px;
  }
}
