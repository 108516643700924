.sliderDiv {
  display: grid;
  grid-template-columns: 400px 1fr;
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  z-index: 7;
  background-color: white;
  gap: 40px;
}
.close {
  position: absolute;
  top: 1%;
  right: 2%;
  cursor: pointer;
  font-size: 30px;
  z-index: 88;
}
.thumbnail li {
  transition: all 0.2s ease-in-out;
  border: 3px solid transparent !important;
}
.thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mainSlider img {
  width: 90%;
  object-fit: fill;
  height: 90%;
  border-radius: 20px;
}
.mainSlider li {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 1226px) {
  .thumbnail {
    display: none;
  }
  .sliderDiv {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 678px) {
  .mainSlider img {
    width: 95%;
    object-fit: contain;
  }
}
