.faq {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1320px;
  padding: 40px 20px;
  border-radius: 20px;
  margin-bottom: 50px;
  background-color: #f2f7fb;
}
.faq h1 {
  color: #020202;
  font-size: 3rem;
  text-align: center;
}
.faq h2 {
  font-size: 1rem;
  font-weight: 400;
  color: #020202;
  margin-top: 10px;
  text-align: center;
}
.faqCol {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin-top: 40px;
}
@media screen and (max-width: 500px) {
  .faq h1 {
    font-size: 35px;
  }
}
