.comp {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1320px;
  padding-inline: 20px;
  gap: 40px;
  margin: 3rem 0rem;
  margin-bottom: 100px;
}
.comp h1 {
  font-size: 40px;
  color: #111;
  font-weight: 600;
  text-align: center;
}
.imgRow {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 30px;
}
.imgRow img {
  max-width: 100%;
  object-fit: contain;
  height: 42px;
}
.imgRow .adobe {
  width: 7.625rem;
}
@media screen and (max-width: 435px) {
  .comp h1 {
    font-size: 30px;
  }
}
