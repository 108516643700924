.explore {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 81px;
}
.inputWrapper {
  width: 100%;
  background-image: url("../../../Assets/exploreBg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inputDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
}
.searchBar {
  max-width: 600px;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}
.borderRadius {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.searchBar input {
  flex: 1;
  height: 100%;
  border: none;
  padding-left: 20px;
  font-size: 15px;
  color: var(--grey);
  font-weight: 120;
}
.searchBar input:focus {
  outline: none;
}
.searchBar svg {
  width: 55px;
  height: 100%;
  background-color: white;
  color: var(--grey);
  display: grid;
  place-items: center;
  padding: 18px;
  box-sizing: border-box !important;
  border: none;
  cursor: pointer;
}
.stateDrop {
  width: 90%;
  position: absolute;
  top: 100%;
  max-height: 300px;
  /* height: 500px; */
  max-width: 600px;
  overflow-y: auto;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  box-shadow: 0px 9px 10px 0px rgba(0, 0, 0, 0.1);
}
.searchedItem {
  width: 100%;
  padding: 20px;
  color: #111;
  font-size: 18px;
  text-decoration: none;
}
.searchedItem:hover {
  background-color: #efefef;
}
.displayDrop {
  display: flex;
}
