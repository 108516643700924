.settings {
  display: flex;
  justify-content: left;
  align-items: left;
  flex-direction: column;
  width: 100%;
  padding: 160px 200px;
  gap: 20px;
}
.settings p {
  white-space: pre-line;
  display: block;
  font-size: 17px;
}
.settings h1 {
  font-size: 40px;
  margin-bottom: 30px;
}
.inputCol2 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}
.inputCol h2,
.inputCol2 h2 {
  font-size: 17px;
}

.inputCol2 > * {
  width: 100%;
}
.hr {
  width: 70%;
  background-color: var(--grey);
  height: 2px;
  opacity: 0.2;
}
.settings > button {
  padding: 10px 35px;
  cursor: pointer;
  font-size: 18px;
  background-color: transparent;
  border: 1px solid var(--grey);
}
.bottomSettings {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 150px;
  width: 100%;
}
.bottomSettings form {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 700px;
}
.inputCol {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}
.dropDown {
  min-width: 190px;
  width: auto;
  position: relative;
  background-color: white;
  box-shadow: rgb(27 32 50 / 10%) 0px 6px 16px;
  border-radius: 10px;
}
.dropDown h6 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-color: rgba(22, 22, 26, 0.1);
  color: black;
  padding: 15px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 900;
}
.dropDown h6 svg {
  margin-left: 20px;
  font-size: 10px;
}
.mainDrop {
  position: absolute;
  top: 120%;
  left: 0;
  width: 300px;
  height: 290px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  background: rgb(255, 255, 255);
  box-shadow: rgb(27 32 50 / 10%) 0px 6px 16px;
  border: 1px solid rgba(22, 22, 26, 0.08);
  z-index: 6;
  border-radius: 20px;
  gap: 10px;
  transform: translateY(40px);
  opacity: 0;
  transition: all 0.4s ease-in-out;
  z-index: -1;
}

.mainDrop ul {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  list-style: none;
  max-height: 100%;
  overflow-y: auto;
}
.mainDrop ul li {
  padding: 10px 0px;
  cursor: pointer;
  width: 100%;
}
.mainDrop ul::-webkit-scrollbar-track,
.subDrop::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.mainDrop ul::-webkit-scrollbar,
.subDrop::-webkit-scrollbar {
  width: 4px;
  background-color: rgba(22, 22, 26, 0.08);
}

.mainDrop ul::-webkit-scrollbar-thumb,
.subDrop::-webkit-scrollbar-thumb {
  border-radius: 100vh;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}
.activeDrop {
  transform: translateY(0px);
  opacity: 1;
  z-index: 3;
}
.inputCol h2 {
  font-size: 17px;
}
.inputCol input,
.inputCol textarea {
  padding-left: 8px;
  border: 1px solid #ccc;
  background: none;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  height: 42px;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  color: #333;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  width: 100%;
  resize: none;
}
.inputCol textarea {
  height: 186px;
  width: 100%;
  padding-top: 4px;
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.leftRow {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.leftRow svg {
  font-size: 25px;
  color: #1da1f2;
}
.leftRow h2 {
  font-size: 16px;
}
.bottomSettings form h3 {
  font-size: 18px;
  margin-bottom: 5px;
}
.bottomSettings form p {
  font-size: 14px;
  color: var(--grey);
}
.ownerDetails {
  display: flex;
  justify-content: flex-start;
  align-items: flex-left;
  flex-direction: column;
  gap: 4px;
  overflow: hidden;
}
.inputStack {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  border: 2px solid #ccc;
  border-radius: 15px;
  overflow: hidden;
}
.inputDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 5px 10px;
  gap: 10px;
}
.inputDiv input {
  width: 100%;
  border: none;
}
.inputDiv input:focus {
  outline: none;
}
.inputDiv svg {
  font-size: 20px;
  color: var(--grey);
}
.inputDiv:first-of-type {
  border-bottom: 2px solid #ccc;
}
.inputDiv:last-of-type {
  border-top: 2px solid #ccc;
}
.bottomSettings form button[type="submit"] {
  padding: 15px 30px;
}
.picSettings {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 50px;
}
.profilePic {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
}
.profilePic h4 {
  font-size: 17px;
}
.pictureWrap {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}

.pictureWrap img {
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.picture {
  width: 100%;
  height: 100%;
  background-color: var(--purple);
}
.bannerWrap {
  width: 320px;
  height: 170px;
  border-radius: 15px;
  overflow: hidden;
}
.bannerWrap img {
  width: 100%;
  object-fit: cover;
}
.banner {
  width: 100%;
  height: 100%;
  background-color: var(--grey);
  opacity: 0.6;
}
.typeRow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.typeBox {
  border: 2px solid rgb(229, 232, 235);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 108px;
  gap: 15px;
  cursor: pointer;
  transition: all 0.2s ease 0s;
  color: rgb(4, 17, 29);
}
.typeRow .typeBox:first-of-type {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-right: 0px;
}
.typeRow .typeBox:last-of-type {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.typeBox svg {
  font-size: 20px;
}
.typeBox h2 {
  margin-bottom: 0px !important;
  font-size: 15px;
}
.activeType {
  background-color: rgb(243, 251, 254);
}
.typeBox:hover {
  box-shadow: rgb(0 0 0 / 10%) 0px 2px 10px;
}
@media screen and (max-width: 870px) {
  .bottomSettings {
    flex-direction: column;
    width: 100%;
    gap: 50px;
  }
  .bottomSettings form {
    width: 100%;
  }
}
