.overlay {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  cursor: pointer;
  left: 0;
  z-index: 80;
}
.notifBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 82;
  width: 95%;
  max-width: 450px;
  height: 250px;
}
.notifBox svg {
  font-size: 50px;
}
.notifBox p {
  font-size: 20px;
  text-align: center;
}
.notifBox .close {
  font-size: 20px;
  position: absolute;
  top: 4%;
  cursor: pointer;
  right: 4%;
}
.notifBox button {
  width: 100%;
  height: 50px;
  font-size: 20px;
  margin-top: auto;
}
