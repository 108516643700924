.learn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  max-width: 1320px;
  gap: 20px;
  margin: 100px 0px;
  padding-inline: 20px;
}
.learn h1 {
  font-size: 40px;
  font-weight: bold;
  max-width: 600px;
}
.learn p {
  max-width: 600px;
  font-size: 20px;
}
.learn button {
  padding: 15px 30px;
  width: 300px;
  font-size: 17px;
}

@media screen and (max-width: 680px) {
  .learn h1 {
    font-size: 40px;
  }
}
