.makeOffer,
.offerDiv {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  max-height: 735px;
  max-width: 700px;
  border-radius: 16px;
  z-index: 6;
  height: 90%;
  width: 90%;
  overflow-y: auto;
  padding: 40px 25px;
}
.offerDiv {
  min-height: 500px;
  height: auto;
  padding: 20px 25px;
}
.overlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 5;
  left: 0;
  top: 0;
  cursor: pointer;
}
.makeOffer h2,
.header h2 {
  font-weight: 600;
  font-size: 22px;
  color: rgb(4, 17, 29);
  text-align: center;
}
.makeOffer img {
  height: 114px;
  object-fit: contain;
}
.makeOffer > p {
  font-weight: 400;
  font-size: 16px;
  color: rgb(4, 17, 29);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;
}
.makeOffer > p svg {
  color: gray;
  font-size: 20px;
}
.infoTable {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border: 1px solid rgb(229, 232, 235);
  border-radius: 10px;
}
.tableRow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 20px 24px;
  border-bottom: 1px solid rgb(229, 232, 235);
}
.infoTable .tableRow:last-of-type {
  border: none;
}
.tableRow h3 {
  width: 220px;
  font-weight: 400;
  font-size: 18px;
  color: rgb(112, 122, 131);
}
.tableRow a {
  font-weight: 600;
  font-size: 16px;
  color: var(--purple);
  text-decoration: none;
  margin-right: 10px;
}
.tableRow p {
  color: rgb(112, 122, 131);
  font-size: 14px;
}
.tableRow h4 {
  font-weight: 600;
  font-size: 16px;
  color: rgb(4, 17, 29);
  margin-right: 10px;
  display: inline-block;
}
.show {
  border: none;
  color: rgb(112, 122, 131);
  border-top: 1px solid rgb(229, 232, 235);
  color: rgb(112, 122, 131);
  width: 100%;
  font-weight: 600;
  font-size: 18px;
  height: 57px;
  background-color: transparent;
  cursor: pointer;
}
.inputDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.inputDiv input {
  height: 24px;
  width: 24px;
}
.inputDiv label {
  font-weight: 400;
  font-size: 18px;
  color: rgb(4, 17, 29);
  font-size: 16px;
  line-height: 1.6;
}
.close {
  position: absolute;
  top: 2%;
  right: 4%;
  color: rgb(112, 122, 131);
  font-size: 25px;
  cursor: pointer;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.header svg {
  color: rgb(112, 122, 131);
  font-size: 25px;
  cursor: pointer;
}
.dropDown {
  min-width: 120px;
  width: auto;
  height: 100%;
  position: relative;
}
.dropDown button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-color: rgba(22, 22, 26, 0.1);
  color: rgb(22, 22, 26);
  background: transparent;
  padding: 0px 10px;
  height: 100%;
  border-radius: 14px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 900;
  position: relative;
  width: 100%;
}
.dropDown button svg {
  font-size: 10px;
}
.dropDown button p {
  display: flex;
  justify-content: center;
  gap: 3px;
  font-size: 16px;
  align-items: center;
}
.dropDown button p svg {
  font-size: 16px;
}
.mainDrop {
  position: absolute;
  top: 120%;
  left: 0;
  width: 300px;
  max-height: 190px;
  height: auto;
  display: none;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  background: rgb(255, 255, 255);
  box-shadow: rgb(27 32 50 / 10%) 0px 6px 16px;
  border: 1px solid rgba(22, 22, 26, 0.08);
  z-index: -1;
  border-radius: 20px;
  pointer-events: none;
  gap: 10px;
  transition: all 0.4s ease-in-out;
}

.mainDrop ul {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  list-style: none;
  max-height: 100%;
  overflow-y: auto;
}
.mainDrop ul li {
  padding: 10px 0px;
  width: 100%;
  cursor: pointer;
}
.mainDrop ul::-webkit-scrollbar-track,
.subDrop::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.mainDrop ul::-webkit-scrollbar,
.subDrop::-webkit-scrollbar {
  width: 4px;
  background-color: rgba(22, 22, 26, 0.08);
}

.mainDrop ul::-webkit-scrollbar-thumb,
.subDrop::-webkit-scrollbar-thumb {
  border-radius: 100vh;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}
.activeDrop {
  display: flex;
  z-index: 6;
  pointer-events: all;
}
.inputCol {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 5px;
}
.inputRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 5px;
  height: 48px;
}
.inputRow h5 {
  font-weight: 600;
  font-size: 16px;
  color: rgb(4, 17, 29);
}
.inputRow p,
.inputCol > p {
  color: rgb(53, 56, 64);
  font-weight: 400;
  font-size: 14px;
}
.inputCol > p {
  align-self: flex-end;
}
.inputRow input {
  width: 100%;
  height: 100%;
  border-color: rgba(22, 22, 26, 0.1);
  color: rgb(22, 22, 26);
  border-radius: 14px;
  padding-inline: 10px;
  font-size: 16px;
}
.offerDiv button {
  width: 100%;
  height: 50px;
  font-size: 20px;
  margin-top: auto;
}
@media screen and (max-width: 644px) {
  .tableRow {
    gap: 10px;
  }
  .tableRow h3 {
    min-width: 150px;
    width: 150px;
    font-size: 16px;
  }
  .tableRow p {
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    gap: 5px;
  }
  .inputRow:has(.dropDown) {
    flex-direction: column;
    height: 120px;
  }
  .dropDown {
    width: 100%;
  }
}
