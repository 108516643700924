.blog {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  padding: 80px;
  gap: 20px;
  margin: 80px;
}
.blog h1,
.cont2 h1 {
  font-size: 40px;
  color: #111;
  font-weight: 500;
}
.date {
  font-size: 14px !important;
}
.date span {
  color: #999;
}
.blog img {
  max-width: 100%;
  object-fit: contain;
  border-radius: 20px;
  align-self: center;
}
.blog p {
  font-size: 17px;
  line-height: 2;
  color: #353840;
  margin-bottom: 1.2em;
}
.figure {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}
.figure span {
  color: #999;
  font-size: 16px;
}
.blog h6 {
  margin-bottom: -14px;
  margin-top: 20px;
  font-size: 20px;
}
.blog ul {
  padding-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.blog ul li p {
  font-size: 18px;
}
.bottomBlog {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
  padding-top: 50px;
  border-top: 2px solid #999;
  gap: 30px;
}
.userRow {
  width: 100%;
  gap: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.userRow h2 {
  font-size: 20px;
}
.userRow img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.col {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
}
.col:last-of-type {
  text-align: right;
  align-items: flex-end;
}
.col h3 {
  color: #404040;
  font-size: 18px;
  font-weight: 500;
}

.col a {
  font-size: 18px;
  font-weight: bold;
  color: black;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}
.col a:hover {
  color: var(--purple);
}
.cont2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1320px;
  padding-inline: 20px;
  gap: 40px;
  margin-bottom: 100px;
}
@media screen and (max-width: 850px) {
  .blog {
    padding: 0px 20px;
    margin-top: 120px;
  }
}
@media screen and (max-width: 490px) {
  .blog h1 {
    font-size: 2rem;
  }
  .blog p,
  .blog ul li p {
    font-size: 16px;
  }
  .col a {
    font-size: 16px;
  }
  .row {
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .col {
    align-items: flex-start !important;
    text-align: left !important;
    pad: 0px;
  }
}
@media screen and (max-width: 420px) {
  .blog h1 {
    font-size: 1.7rem;
  }
}
