.headerCont {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  transition: all 0.3s ease-in-out;
  border-bottom: 1px solid transparent;
}
.bgWhite {
  background-color: white;
  border-bottom-color: var(--grey);
}
.leftHeader img {
  max-width: 200px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1320px;
  height: 80px;
  padding-inline: 20px;
}
.leftHeader,
.rightHeader,
.navLinks {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.leftHeader input {
  padding-left: 11px;
  border: none;
  border-radius: 6px;
  font-size: 15px;
  background: var(--light-purple);
  width: 274px;
  height: 34px;
  outline: none;
}
.inputDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
}
.searchBar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  border-radius: 8px;
  position: relative;
}
.borderRadius {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.searchBar input:focus {
  outline: none;
}
.searchBar svg {
  width: 55px;
  height: 100%;
  background-color: white;
  color: var(--grey);
  display: grid;
  place-items: center;
  padding: 18px;
  box-sizing: border-box !important;
  border: none;
  cursor: pointer;
}
.stateDrop {
  width: 100%;
  position: absolute;
  top: 100%;
  max-height: 300px;
  /* height: 500px; */
  max-width: 600px;
  overflow-y: auto;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  box-shadow: 0px 9px 10px 0px rgba(0, 0, 0, 0.1);
}
.searchedItem {
  width: 100%;
  padding: 20px;
  color: #111;
  font-size: 18px;
  text-decoration: none;
}
.searchedItem:hover {
  background-color: #efefef;
}
.displayDrop {
  display: flex;
}

.navLinks a {
  color: #111;
  font-size: 16px;
  position: relative;
  text-decoration: none;
  font-weight: 700;
}
.rightHeader button {
  padding: 10px 50px;
}
.rightHeader a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 0%;
  height: 2px;
  background-color: var(--purple);
  transition: all 0.3s ease-in-out;
}

.rightHeader a:hover::before {
  width: 100%;
}
.mobile {
  display: none;
}
@media screen and (max-width: 1020px) {
  .overlay {
    display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 6;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .openOverlay {
    display: block;
  }
  .mobile {
    display: block;
    color: black;
    font-size: 21px;
    cursor: pointer;
    position: relative;
    z-index: 7;
  }
  .navLinks {
    z-index: 6;
    position: fixed;
    right: -600px;
    top: 0;
    width: 400px;
    height: 100%;
    flex-direction: column;
    background-color: white;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 150px;
    padding-left: 30px;
    gap: 30px;
    border-left: 1px solid var(--grey);
    transition: all 0.5s ease-in-out;
  }
  .navLinks a {
    font-size: 18px;
  }
  .openHeader {
    right: 0;
  }
}
@media screen and (max-width: 761px) {
  .leftHeader input {
    display: none;
  }
}
@media screen and (max-width: 473px) {
  .navLinks {
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0px;
  }
  .leftHeader img {
    max-width: 120px;
  }
  .rightHeader button {
    padding: 12px 20px;
  }
}
