.table {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1320px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 40px;
}
.head {
  display: grid;
  column-gap: 20px;
  grid-template-columns: 3.75fr 1fr 1fr 1fr;
  width: 100%;
  padding-inline: 40px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #cfd9f0;
}

.head h2 {
  color: black;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}
.head h2:first-child {
  text-align: left;
}
.content {
  display: grid;
  column-gap: 20px;
  grid-template-columns: 3.75fr 1fr 1fr 1fr;
  width: 100%;
  place-items: center;
  height: 50px;
  border-radius: 0.75rem;
  padding-inline: 40px;
}
.content h3 {
  justify-self: flex-start;
  text-align: left;
  color: #020202;
  font-size: 1.25rem;
  font-weight: 400;
}
.content h4 {
  text-transform: uppercase;
  color: #020202;
  font-size: 1rem;
}
.content p {
  color: #020202;
  font-size: 1.25rem;
}
.content:nth-child(even) {
  background: #ecf0f9;
}
@media screen and (max-width: 652px) {
  .table {
    align-items: flex-start;
    padding-inline: 20px;
    overflow-x: scroll;
  }
  .head,
  .content {
    width: 700px;
  }
}
