.blogBox {
  isolation: isolate;
  border-radius: 10px;
  width: 100%;
  height: 360px;
  padding: 3px;
  position: relative;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  text-decoration: none;
  color: black;
}

.mainBox {
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  height: 100%;
  border-radius: 20px;
  padding: 15px;
}
.blogBg {
  width: 100%;
  position: relative;
  height: 234px;
  isolation: isolate;
  overflow: hidden;
}
.blogBg img:first-child {
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-radius: 20px;
}
.blogBg:after {
  content: "";
  left: 0;
  z-index: 1;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent);
  position: absolute;
  border-radius: 20px;
}
.absIco {
  position: absolute;
  bottom: 5%;
  left: 2%;
  max-width: 150px;
  z-index: 2;
}
.blogBox:after {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: -1;
  bottom: 0px;
  height: 100%;
  border-radius: 20px;
  background-image: linear-gradient(82deg, #36beff, #733ff1);
  opacity: 0.5;
  transition: opacity 0.2s, transform 0.2s;
}
.blogBox:hover::after {
  opacity: 1;
}
.blogBox h2 {
  margin-top: 20px;
  font-size: 23px;
  font-weight: 500;
}
