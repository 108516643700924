.footCont {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-top: 1px solid black;
  padding: 20px 0px;
  background-color: var(--dark-purple);
}
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1320px;
  padding-inline: 20px;
}
.leftFooter,
.rightFooter {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.leftFooter img {
  max-width: 140px;
  object-fit: contain;
}
.leftFooter p {
  color: white;
  font-size: 16px;
}
.rightFooter a {
  text-decoration: none;
  background-color: var(--purple);
  width: 34px;
  height: 34px;
  display: grid;
  place-items: center;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  color: white;
}
.rightFooter svg {
  font-size: 16px;
  color: inherit;
}
.rightFooter a:hover {
  color: var(--purple);
  background-color: white;
}
@media screen and (max-width: 982px) {
  .footer {
    flex-direction: column;
    gap: 20px;
  }
  .leftFooter {
    flex-direction: column;
  }
  .paddingBot {
    padding-bottom: 6rem;
  }
}
