.contact {
  margin-top: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 100px;
  flex-direction: column;
  width: 100%;
  min-height: calc(100vh - 100px);
  gap: 40px;
}
.contact form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;

  max-width: 500px;
}
.inputCol {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 5px;
}
.inputCol input,
.inputCol textarea {
  padding-left: 8px;
  border: 1px solid #ccc;
  background: none;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  height: 42px;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  color: #333;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  width: 100%;
}
.inputCol textarea {
  height: 100px !important;
  resize: none;
  padding-top: 8px;
}
.inputCol h2 {
  font-size: 17px;
}
.contact form button {
  padding: 16px 80px;
  font-size: 18px;
}
