.metaConnect {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  max-width: 1000px;
  width: 100%;
  min-height: 100vh;
  padding: 100px 20px;
  margin: 0 auto;
}
.metaConnect h1 {
  font-size: 40px;
}
.metaConnect p {
  font-size: 20px;
  margin-top: 10px;
}
.metaConnect p span {
  color: var(--purple);
}
.metaRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 15px;
  background-color: white;
  padding: 20px;
  cursor: pointer;
  margin-top: 30px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
}
.leftMeta {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.leftMeta img {
  max-width: 40px;
  object-fit: contain;
}
.pop {
  background-color: var(--purple);
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 8px;
}
@media screen and (max-width: 414px) {
  .metaConnect h1 {
    font-size: 30px;
  }
}
