.card {
  min-height: 310px;
  max-height: 360px;
  width: 340px;
  background-color: white;
  border: 1px solid #ddd;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  gap: 20px;
  border-radius: 10px;
  transition: all 0.4s ease-in-out;
  padding-bottom: 20px;
  overflow: hidden;
  text-decoration: none;
  color: black;
  overflow-y: auto;
  box-shadow: rgb(0 0 0 / 8%) 0px 4px 15px;
  margin: 20px 0px;
}
.card:hover {
  box-shadow: rgb(0 0 0 / 15%) 0px 6px 25px;
}
.card > a {
  width: 100%;
  text-decoration: none;
}
.cardDesc h3 {
  color: var(--purple);
  font-size: 18px;
}
.cardDesc h4 {
  font-size: 13px;
  color: var(--grey);
  font-weight: 400;
}

.cardBg {
  width: 100%;
  object-fit: cover;
  margin-bottom: 5px;
  height: 228px;
}
.cardDesc {
  padding-inline: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}
.cardDesc h5 {
  font-size: 12px;
  color: var(--grey);
  word-wrap: break-word;
}
.card h5 span {
  color: #111;
  font-size: 16px;
}
.card p {
  color: var(--purple);
  font-weight: 700;
}

.absRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 0%;
  height: 60px;
  isolation: isolate;
  padding-inline: 20px;
}
.absRow::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 50%, transparent);
  z-index: -1;
  width: 100%;
  height: 100%;
}
.absRow svg {
  font-size: 20px;
  color: white;
  cursor: pointer;
}
.absRow .redColor {
  color: red;
}
.absRow h6 {
  font-size: 18px;
  color: white;
}
.cardDesc a {
  font-size: 16px;
  color: #111;
  text-decoration: underline;
}
.row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 10px;
}
.row p {
  color: black;
  font-weight: 300;
  padding-right: 5px;
  border-right: 2px solid lightgray;
  font-weight: 300;
}
.row strong {
  color: black;
  font-weight: 400;
}
