.update {
  background-color: #f2f7fb;
  width: 97%;
  border-radius: 20px;
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-left: 3.5rem;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
  max-width: 1320px;
  flex-direction: column;
  margin: 100px 0px;
  margin-bottom: 50px;
}
.update h1 {
  color: var(--purple);
  font-size: 4rem;
}
.update p {
  font-size: 1.5rem;
}
.inputDiv {
  max-width: 600px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 20px;
  border: 1px solid #b6d1e3;
  color: #333;
  height: 50px;
  border-radius: 15px;
}
.inputDiv input {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  font-size: 16px;
}
.inputDiv svg {
  color: #333;
  opacity: 0.6;
  cursor: pointer;
}
.inputDiv input:focus {
  outline: none;
}
@media screen and (max-width: 780px) {
  .update h1 {
    font-size: 30px;
  }
  .update p {
    font-size: 18px;
  }
  .update {
    padding-inline: 20px;
    align-items: center;
    text-align: center;
  }
}
