.settings {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 120px 20px;
  gap: 20px;
}
.settings h1 {
  font-size: 40px;
}

.hr {
  width: 70%;
  background-color: var(--grey);
  height: 2px;
  opacity: 0.2;
}
.settings > button {
  padding: 10px 35px;
  cursor: pointer;
  font-size: 18px;
  background-color: transparent;
  border: 1px solid var(--grey);
}
.bottomSettings {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 150px;
}
.bottomSettings form {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}
.inputCol {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}
.inputCol h2 {
  font-size: 17px;
}
.inputCol input,
.inputCol textarea {
  padding-left: 8px;
  border: 1px solid #ccc;
  background: none;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  height: 42px;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  color: #333;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  width: 100%;
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.leftRow {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.leftRow svg {
  font-size: 25px;
  color: #1da1f2;
}
.leftRow h2 {
  font-size: 16px;
}
.bottomSettings form h3 {
  font-size: 18px;
  margin-bottom: 5px;
}
.bottomSettings form p {
  font-size: 14px;
  color: var(--grey);
}
.ownerDetails {
  display: flex;
  justify-content: flex-start;
  align-items: flex-left;
  flex-direction: column;
  gap: 4px;
  overflow: hidden;
}
.inputStack {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  border: 2px solid #ccc;
  border-radius: 15px;
  overflow: hidden;
}
.inputDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 5px 10px;
  gap: 10px;
  border-bottom: 2px solid #ccc;
}
.inputDiv input {
  width: 100%;
  border: none;
}
.inputDiv input:focus {
  outline: none;
}
.inputDiv svg {
  font-size: 20px;
  color: var(--grey);
 
}

.bottomSettings form button[type="submit"] {
  padding: 15px 30px;
}
.picSettings {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 50px;
}
.profilePic {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
}
.profilePic h4 {
  font-size: 17px;
}
.pictureWrap {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}

.pictureWrap img {
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.picture {
  width: 100%;
  height: 100%;
  background-color: var(--purple);
}
.bannerWrap {
  width: 320px;
  height: 170px;
  border-radius: 15px;
  overflow: hidden;
}
.bannerWrap img {
  width: 100%;
  object-fit: cover;
}
.banner {
  width: 100%;
  height: 100%;
  background-color: var(--grey);
  opacity: 0.6;
}
@media screen and (max-width: 870px) {
  .bottomSettings {
    flex-direction: column;
    width: 100%;
    gap: 50px;
  }
  .bottomSettings form {
    width: 100%;
  }
}
