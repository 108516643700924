.faqTab {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #020202;
  width: 100%;
  padding-inline: 20px;
  border-bottom: 1px solid #cfd9f0;
  padding-bottom: 10px;
  padding-top: 10px;
}
.faqBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;
  cursor: pointer;
}
.faqBtn h3 {
  font-size: 1.5rem;
  pointer-events: none;
}
.faqBtn svg {
  font-size: 1.5rem;
  pointer-events: none;
  transition: all 0.5s ease-in-out;
}
.answer {
  overflow: hidden;
  max-height: 0px;
  transition: all 1s linear;
}
.answer p {
  text-align: center;
  font-size: 16px;
  line-height: 1.3;
}

.overflow {
  overflow: visible;
  max-height: 800px;
}
.rotate {
  transform: rotate(45deg);
}
@media screen and (max-width: 500px) {
  .faqTab {
    padding-inline: 0px;
  }
}
