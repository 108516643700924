.links {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  width: 100%;
  max-width: 1320px;
  padding-top: 4rem;
  padding-inline: 20px;
  padding-bottom: 4rem;
}
.col {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
}
.col h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: white;
}
.col a,
.col p {
  font-size: 16px;
  color: white;
  text-decoration: none;
}
.inputDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  border-radius: 5px;
  height: 35px;
  margin: 10px 0px;
}
.inputDiv input {
  width: 100%;
  height: 100%;
  padding-left: 10px;
  background: hsla(0, 0%, 100%, 0.7);
  border: 1px solid #333;
  border-right: none;
  padding: 6px 12px;
  box-shadow: 2px 2px 20px 0 rgb(20 20 20 / 5%);
  border-radius: 5px 0px 0px 5px;
}
.inputDiv input:focus {
  outline: none;
}
.inputDiv svg {
  text-align: center;
  font-size: 28px;
  min-width: 62px;
  background: var(--purple);
  color: #fff;
  height: 100%;
  padding: 8px;
  cursor: pointer;
  box-sizing: border-box !important;
  border-radius: 0 5px 5px 0;
  -moz-border-radius: 0 5px 5px 0;
  -webkit-border-radius: 0 5px 5px 0;
}
@media screen and (max-width: 914px) {
  .links {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    place-items: center;
    row-gap: 40px;
    padding-top: 2rem;
  }
  .col {
    align-items: center;
    text-align: center;
  }
}
