.heroCont {
  padding-top: 80px;
  background-image: url("https://assets-global.website-files.com/6425f546844727ce5fb9e5ab/64377ee0d96a223b1d5c6700_01_Homepage_Hero-poster-00001.jpg"
  );
  background-size: cover;
  background-position: right;
  min-height: max(70vh, 500px);
}
.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1320px;
  gap: 3rem;
  padding-inline: 20px;
}
.leftHero {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  text-align: center;
}
.leftHero h4 {
  text-transform: uppercase;
  color: var(--purple);
  font-size: 16px;
}
.leftHero h1 {
  color: #111;
  font-size: 50px;
  line-height: 1.1;
  max-width: 600px;
  margin: 0 auto;
  text-transform: capitalize;
}
/* gradient --------------- */
.leftHero h1 .gradient {
  background: linear-gradient(90deg, #007cf0, #00dfd8);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.leftHero p {
  color: var(--grey);
  font-size: 18px;
  line-height: 1.7em;
}
.btnDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.btnDiv button,
.btnDiv a {
  font-size: 16px;
  padding: 16px 70px;
  
}
@media screen and (max-width: 1072px) {
  .leftHero h1 {
    font-size: 40px;
  }
}
@media screen and (max-width: 860px) {
  .rightHero {
    display: none;
    flex: none;
  }
  .heroCont {
    min-height: auto;
    padding: 130px 0px;
  }
  .leftHero {
    flex: none;
    width: 100%;
  }
  .leftHero h1 {
    font-size: 40px;
  }
}
@media screen and (max-width: 440px) {
  .btnDiv {
    flex-direction: column;
    width: 100%;
  }
  .btnDiv a {
    width: 100%;
  }
}

.update {
  background-image: url("https://assets-global.website-files.com/6425f546844727ce5fb9e5ab/6430980fe2d0578e8cd69453_background.jpeg"
  );
  width: 95%;
  height: 600px;
  border-radius: 20px;
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-left: 4.5rem;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
  max-width: 1320px;
  flex-direction: column;
  margin: 100px 0px;
  margin-bottom: 50px;
}
.update h1 {
  letter-spacing: -.06em;
    margin-bottom: 50px;
    font-family: Inter, sans-serif;
    font-size: 129px;
    font-weight: 500;
    line-height: 159px;
}
.update hr {
  display: block;
  border-width: 1px;
  color: #111;
  width: 100%;
}
.update h2 {
  font-family: Inter, sans-serif;
  letter-spacing: -.05em;
    margin-bottom: 23px;
    font-family: Inter, sans-serif;
    font-size: 32px;
    font-weight: 500;
}
.inputDiv {
  max-width: 600px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 20px;
  border: 1px solid #b6d1e3;
  color: #333;
  height: 50px;
  border-radius: 15px;
}
.inputDiv input {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  font-size: 16px;
}
.inputDiv svg {
  color: #333;
  opacity: 0.6;
  cursor: pointer;
}
.inputDiv input:focus {
  outline: none;
}
@media screen and (max-width: 780px) {
  .update h1 {
    letter-spacing: -.06em;
    margin-bottom: 50px;
    font-family: "Inter";
    font-size: 69px;
    font-weight: 500;
    line-height: 129px;
  }
  .update p {
    font-size: 18px;
  }
  .update {
    padding-inline: 20px;
    align-items: center;
    text-align: center;
  }
}
