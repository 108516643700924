@import url("https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter";
  font-weight: 100;
  line-height: 1;
}
:root {
  --purple: rgb(0, 106, 255);
  --grey: #727272;
  --light-purple: rgba(131, 100, 226, 0.1);
  --dark-purple: rgb(22, 51, 92);
}
.links-cont {
  background-color: var(--dark-purple);
  margin: 0 !important;
}
.cta-btn {
  text-align: center;
  color: #fff;
  background: var(--purple);
  border-radius: 6px;
  outline: 0;
  font-weight: 800;
  padding: 8px 20px;
  font-size: 14px;
  border: none;
  transition: all 0.3s ease;
  cursor: pointer;
  text-decoration: none;
}
.grey-btn {
  border-color: transparent;
  color: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s ease;
}
.cta-btn:hover {
  box-shadow: 2px 2px 20px 0 rgba(46, 47, 49, 0.7);
}
.grey-btn:hover {
  box-shadow: 2px 2px 20px 0 rgb(0 0 0 /10%);
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.collectionSlider .splide__pagination__page.is-active {
  background: #111 !important;
}
.collectionSlider .splide__slide {
  display: flex !important;
  justify-content: center !important;
}
.collectionSlider .splide__pagination {
  bottom: -19px !important;
}
.trend {
  background-color: rgb(249, 249, 251);
  padding: 40px 0px;
  margin-top: 20px;
}
/* toggle switch ----------------------------------- */

.switch input {
  display: none;
}

.switch {
  display: inline-block;
  width: 50px; /*=w*/
  height: 27px; /*=h*/
  margin: 4px;
  position: relative;
}

.slider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--grey);
  border-radius: 100vh;
  cursor: pointer;
  border: 4px solid var(--grey);
  overflow: hidden;
  transition: 0.2s;
}

.slider:before {
  position: absolute;
  content: "";
  width: 18px;
  height: 18px;
  background-color: white;
  border-radius: 50%;
  top: 50%;
  transform: translateX(1px) translateY(-50%); /*translateX(-(w-h))*/
  transition: 0.2s;
}

input:checked + .slider:before {
  transform: translateX(24px) translateY(-50%);
}
input:checked + .slider {
  background-color: var(--purple);
  border-color: var(--purple);
}

.text-center {
  text-align: center;
}

.align-items-center {
  align-items: center;
}

.align-self-center {
  align-self: center;
}

.Toastify {
  font-size: 12px;
}

.p-relative {
  position: relative;
}

.w-100 {
  width: 100%;
}

.centered {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.thumbnail-slide .is-active {
  border-color: black !important;
  opacity: 0.6;
}
@media screen and (max-width: 678px) {
  .main-slide .splide__pagination {
    bottom: 4% !important;
  }
}
.main-slide .splide__pagination__page.is-active {
  background: black !important;
  opacity: 1 !important;
}
.main-slide .splide__pagination__page {
  background: black !important;
  opacity: 0.4 !important;
}
