.review {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1320px;
  padding-inline: 20px;
  margin: 80px 0px;
}
.review img {
  width: 108px;
  height: 108px;
  object-fit: contain;
}
.review p {
  margin: 30px 0px;
  color: #020202;
  font-size: 1.8rem;
  line-height: 140%;
  font-weight: 100;
  text-align: center;
}
.review h2 {
  font-size: 1.5rem;
  font-weight: 700;
}
.review h3 {
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 1.5rem;
  font-weight: 500;
}
.review h4 {
  font-size: 1.5rem;
  color: var(--purple);
  font-weight: 500;
}
@media screen and (max-width: 500px) {
  .review p {
    font-size: 1.4rem;
  }
}
