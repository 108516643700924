.loading {
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  height: 100%;
  width: 100%;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: 1rem;
  padding-right: 1rem;
}
.loading img {
  width: 100px;
  object-fit: contain;
}
.progressWrap {
  margin-top: 1rem;
  width: 100%;
  max-width: 500px;
}
