.shareModal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 30px 30px;
  border-radius: 8px;
  width: 95%;
  max-width: 600px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
  flex-direction: column;
}
.overlay {
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100vh;
  cursor: pointer;
}
.shareHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.shareHead h1 {
  width: 100%;
  text-align: center;
}
.shareHead svg {
  font-size: 25px;
  background-color: white;
  border-radius: 50%;
  box-shadow: rgb(0 0 0 / 15%) 0px 6px 25px;
  cursor: pointer;
  width: 35px;
  padding: 5px;
  height: 35px;
  box-sizing: border-box !important;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
}
.flex img {
  max-width: 150px;
  width: 100%;
  border-radius: 8px;
  object-fit: contain;
}
.col {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}
.col h2 {
  font-size: 25px;
}
.col p {
  font-size: 17px;
}
.shareModal button {
  width: 300px;
  margin-top: 20px;
  height: 60px;
  font-size: 18px;
}
@media screen and (max-width: 446px) {
  .flex {
    flex-direction: column;
  }
  .col {
    align-items: center;
    text-align: center;
  }
  .flex img {
    width: 100%;
    max-width: none;
  }
}
