.searchPage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: max(100vh, 800px);
  margin-top: 81px;
  padding-inline: 20px;
  margin-bottom: 50px;
}
.leftSearch {
  flex: 0.4;
  height: 100%;
}
.leftSearch iframe {
  width: 100%;
  height: 100%;
}
.rightSearch {
  flex: 0.6;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-height: 100%;
  gap: 20px;
  overflow-y: auto;
}
.overflowNone {
  overflow-y: visible;
  align-self: flex-start;
  margin-top: 40px;
}
.nofound {
  font-size: 40px;
  overflow: visible;
  line-height: 1;
}
@media screen and (max-width: 1213px) {
  .rightSearch,
  .leftSearch {
    flex: 0.5;
  }
}

.pagination {
  width: 100%;
}
.pagination > ul {
  max-width: 680px;
  margin-left: auto;
  margin-right: auto;
  list-style-type: none;
  display: flex;
  justify-content: center;
  gap: 8px;
}
.pagination > ul > li > a {
  display: block;
  padding: 0.5rem 0.75rem;
  border: 1px solid var(--purple);
  color: var(--purple);
  border-radius: 4px;
  cursor: pointer;
  transition: 0.4s ease;
}
.selected > a,
.pagination > ul > li > a:hover {
  background-color: var(--purple);
  color: #fff !important;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}

@media screen and (max-width: 970px) {
  .searchPage {
    margin-top: 100px;
    height: auto;
    flex-direction: column;
    gap: 40px;
  }
  .leftSearch {
    width: 100%;
    height: 400px;
  }
  .rightSearch,
  .leftSearch {
    flex: none;
    width: 100%;
    overflow: visible;
  }
}
